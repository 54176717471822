module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KCFMP2X","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toothpaste Drops","short_name":"Drops","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a9e2aeb87817d2a9b5777d5dc78d0eaf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@jamal./gatsby-theme-shopify-manager/gatsby-browser.js'),
      options: {"plugins":[],"storeUrl":"toothpaste-drops.myshopify.com","password":"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","storefrontAccessToken":"6bf55a9042432d8e62c7a98290f9e3a8"},
    },{
      plugin: require('../plugins/gatsby-source-drops/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"JeO93y6kNGVoKkNRun5JGFTM406r7w"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
