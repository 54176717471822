import "bootstrap/dist/css/bootstrap.min.css";
// import 'jquery/dist/jquery.min.js'
// import "bootstrap/dist/js/bootstrap.min.js";
// import "@popperjs/core/dist/umd/popper.min.js";

import "./src/styles/styles.scss"
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)

export const shouldUpdateScroll = ({ routerProps }) => {
  const hasAnchor = !!routerProps.location.hash; // (ie. /#features)

  return hasAnchor
}
