exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-aboutus-js": () => import("./../../../src/pages/pages/aboutus.js" /* webpackChunkName: "component---src-pages-pages-aboutus-js" */),
  "component---src-pages-pages-agb-js": () => import("./../../../src/pages/pages/agb.js" /* webpackChunkName: "component---src-pages-pages-agb-js" */),
  "component---src-pages-pages-disclaimer-js": () => import("./../../../src/pages/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-pages-disclaimer-js" */),
  "component---src-pages-pages-imprint-js": () => import("./../../../src/pages/pages/imprint.js" /* webpackChunkName: "component---src-pages-pages-imprint-js" */),
  "component---src-pages-pages-privacy-policy-js": () => import("./../../../src/pages/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-pages-privacy-policy-js" */),
  "component---src-pages-products-monatspaket-js": () => import("./../../../src/pages/products/monatspaket.js" /* webpackChunkName: "component---src-pages-products-monatspaket-js" */),
  "component---src-pages-products-probierpaket-js": () => import("./../../../src/pages/products/probierpaket.js" /* webpackChunkName: "component---src-pages-products-probierpaket-js" */),
  "component---src-pages-products-sonderangebot-js": () => import("./../../../src/pages/products/sonderangebot.js" /* webpackChunkName: "component---src-pages-products-sonderangebot-js" */),
  "component---src-pages-products-starterkit-js": () => import("./../../../src/pages/products/starterkit.js" /* webpackChunkName: "component---src-pages-products-starterkit-js" */),
  "component---src-pages-products-zubehoerbox-js": () => import("./../../../src/pages/products/zubehoerbox.js" /* webpackChunkName: "component---src-pages-products-zubehoerbox-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-surveys-fb-js": () => import("./../../../src/pages/surveys/fb.js" /* webpackChunkName: "component---src-pages-surveys-fb-js" */),
  "component---src-pages-surveys-fbresult-js": () => import("./../../../src/pages/surveys/fbresult.js" /* webpackChunkName: "component---src-pages-surveys-fbresult-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

